<template>
    <div>
        <base-header base-title="Unit Antrian"></base-header>
        <!-- begin:: Content -->
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh
                                :table-name="tableName"
                                :on-refresh="fetch"
                            ></auto-refresh>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <!-- <data-tables :table="table"></data-tables> -->
                    <v-server-table
                        :columns="columns"
                        :options="options"
                        ref="table"
                        @loaded="onLoaded(0)"
                        @loading="onLoaded(1)"
                    >
                        <div v-if="isLoading" slot="afterBody">
                            <h6 class="txt">loading..</h6>
                        </div>
                        <div slot="displayInKiosk" slot-scope="{ row }">
                            <span
                                :class="
                                    `p-2 mt-1 badge ${
                                        row.displayInKiosk
                                            ? 'badge-success'
                                            : 'badge-danger'
                                    }`
                                "
                                >{{ row.displayInKiosk ? "Ya" : "Tidak" }}</span
                            >
                            <br />
                        </div>
                        <div slot="action" slot-scope="{ row }">
                            <btn-detail
                                :rowData="row"
                                :queueUnitVue="$store"
                                @showModal="showModalDetail"
                            ></btn-detail>
                        </div>
                    </v-server-table>
                </div>
                <!-- <div
          v-else
          class="m-auto p-5 d-flex flex-row h-100"
        >
          <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
          <h5 class="my-auto pl-5">Loading ...</h5>
        </div> -->
                <modal-detail
                    v-if="isShowModalDetail"
                    :show-modal.sync="isShowModalDetail"
                    :modal-name="modalDetailName"
                    :table-name="tableName"
                    :modal-data="dataDetail"
                ></modal-detail>
            </div>
        </div>
        <!-- end:: Content -->
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import AutoRefresh from "../_general/AutoRefresh";
import BtnDetail from "./_components/BtnDetail";
// import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import BaseTitle from "../_base/BaseTitle";
import BaseHeader from "../_base/BaseHeader";
import DataTables from "../_table/DataTables";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const QueueUnitRepository = RepositoryFactory.get("queueUnit");

export default {
    components: {
        AutoRefresh,
        DataTables,
        BaseTitle,
        BaseHeader,
        BtnDetail,
        ModalDetail: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import(/* webpackChunkName: "modal-queue-unit-detail" */ "./_components/ModalDetail.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            isLoaded: false,
            isShowModalDetail: false,
            dataDetail: null,
            columns: [
                "id",
                "displayName1",
                "displayName2",
                "averageWaitTimeMinute",
                "tujuanRujukan.nama",
                "jadwal.hari",
                "jadwal.jamMulai",
                "jadwal.jamSelesai",
                "displayInKiosk",
                "action"
            ],
            options: {
                texts: {
                    filterBy: "Cari"
                },
                filterable: [
                    "id",
                    "displayName1",
                    "displayName2",
                    "averageWaitTimeMinute",
                    "tujuanRujukan.nama",
                    "jadwal.hari",
                    "jadwal.jamMulai",
                    "jadwal.jamSelesai",
                    "displayInKiosk"
                ],
                headings: {
                    id: "ID",
                    displayName1: "Display Name 1",
                    displayName2: "Display Name 2",
                    averageWaitTimeMinute: "durasi",
                    "tujuanRujukan.nama": "Poli",
                    displayInKiosk: "Tampilkan di Kiosk",
                    "jadwal.hari": "hari",
                    "jadwal.jamMulai": "jam mulai",
                    "jadwal.jamSelesai": "jam selesai",
                    action: "Actions"
                },
                dateColumns: ["createdDate", "lastModifiedDate"],
                debounce: 500,
                filterByColumn: true,
                listColumns: {
                    displayInKiosk: [
                        {
                            id: "",
                            text: "Semua"
                        },
                        {
                            id: false,
                            text: "Tidak"
                        },
                        {
                            id: true,
                            text: "Ya"
                        }
                    ],
                    "jadwal.hari": [
                        {
                            id: "MONDAY",
                            text: "MONDAY"
                        },
                        {
                            id: "TUESDAY",
                            text: "TUESDAY"
                        },
                        {
                            id: "WEDNESDAY",
                            text: "WEDNESDAY"
                        },
                        {
                            id: "THURSDAY",
                            text: "THURSDAY"
                        },
                        {
                            id: "FRIDAY",
                            text: "FRIDAY"
                        },
                        {
                            id: "SATURDAY",
                            text: "SATURDAY"
                        },
                        {
                            id: "SUNDAY",
                            text: "SUNDAY"
                        }
                    ]
                },
                toMomentFormat: "YYYY-MM-DD",
                requestFunction(data) {
                    let params = {};
                    let page = {};
                    page.sort = "createdDate,desc";
                    page.page = data.page - 1;
                    page.size = data.limit;
                    this.isLoaded = false;
                    if (_.isEmpty(data.query) && localStorage.login) {
                        data.query = { login: localStorage.login };
                        // setTimes
                    } else {
                        localStorage.removeItem("login");
                    }
                    if (_.isEmpty(data.query)) {
                        vx.modeSearch = false;
                        return QueueUnitRepository.fetch(page).catch(function(
                            e
                        ) {
                            this.dispatch("error", e);
                        });
                    } else {
                        vx.modeSearch = true;

                        let par = {
                            ...(data.query.displayName1 && {
                                "displayName1.contains": data.query.displayName1
                            }),
                            ...(data.query.displayName2 && {
                                "displayName2.contains": data.query.displayName2
                            }),
                            ...(data.query["averageWaitTimeMinute"] && {
                                "averageWaitTimeMinute.equals": data.query["averageWaitTimeMinute"]
                            }),
                            ...(data.query["tujuanRujukan.nama"] && {
                                "tujuanRujukanNama.contains": data.query["tujuanRujukan.nama"]
                            }),
                            ...(data.query["jadwal.hari"] && {
                                dayOfWeeks: data.query["jadwal.hari"]
                            }),
                            ...(data.query["jadwal.jamMulai"] && {
                                "jamMulai.equals": data.query["jadwal.jamMulai"]
                            }),
                            ...(data.query["jadwal.jamSelesai"] && {
                                "jamSelesai.equals": data.query["jadwal.jamSelesai"]
                            }),
                            ...page
                        };
                        // console.log(data.query);

                        // console.log(par);

                        return QueueUnitRepository.fetch(par).catch(function(
                            e
                        ) {
                            vx.showToastError("Error fetch Data");
                            this.dispatch("error", e);
                        });
                    }
                },
                responseAdapter: resp => {
                    return {
                        data: resp.data,
                        count: resp.headers["x-total-count"]
                            ? resp.headers["x-total-count"]
                            : resp.headers["Content-Length"]
                            ? resp.headers["Content-Length"]
                            : resp.data.length
                    };
                },
                rowClassCallback(row) {
                    return row.isNewData ? "highlight" : "";
                },
                filterByColumn: true,
                sortable: [],
                templates: {
                    // jadwal(h, row) {
                    //     if (row.jadwal) {
                    //         return `${row.jadwal.hari}, ${
                    //             row.jadwal.jamMulai
                    //         } - ${row.jadwal.jamSelesai}`;
                    //     }
                    //     return row.jadwal ? row.jadwal.hari : "";
                    // }
                }
            },
            modeSearch: true,
            isLoading: false,
            headTitle: "Unit Antrian",
            modalDetailName: "modal_detail_queue_unit",
            // headTitle: "Unit Antrian",
            tableName: "table_queue_unit"
        };
    },
    watch: {
        isShowModalDetail: function(val) {
            if (!val) {
                this.dataDetail = null;
            }
        }
    },
    methods: {
        onLoaded: function(t) {
            t == 0 ? (this.isLoading = false) : (this.isLoading = true);
        },
        async fetch(resolve) {
            this.$refs.table.getData();
            if (resolve != undefined) {
                resolve();
            }
            // new Promise((resolve, reject) => {
            //   QueueUnitRepository.fetch({}, resolve, reject);
            // }).then(response => {
            //   const data = response.data;
            //   if (this.isLoaded) {
            //     var datatable = $("#" + this.tableName).DataTable();
            //     datatable.clear();
            //     datatable.rows.add(data);
            //     datatable.draw("full-hold");
            //   } else {
            //     // this.table.tableDataResult = data;
            //     this.isLoaded = true;
            //   }
            // }).catch(error => {
            //   var msg = (error.message) ? error.message : error;
            //   this.showToastError('Queue Unit : ' + msg);
            // })
        },
        showModalDetail: function(dat) {
            this.isShowModalDetail = true;
            this.dataDetail = dat;
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {
        // this.fetch();
    }
};
</script>
