<template>
  <div class="text-center">
    <template v-if="checkPermission('QueueUnitResource.GET.QueueUnit')">
      <button
        @click="showQueueUnit()"
        class="btn btn-sm btn-clean btn-icon btn-icon-md"
        title="Detail"
      >
        <i class="la la-eye"></i>
      </button>
    </template>
  </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
  props: {
    queueUnitVue: { type: Object },
    rowData: { type: Object }
  },
  methods: {
    showQueueUnit() {
      this.queueUnitVue.dataDetail = this.rowData;
      this.$emit("showModal", this.rowData)
      // this.queueUnitVue.showModalDetail();
    },
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    }
  }
}
</script>
